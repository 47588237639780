/* Container for the nav bar */
.top-bar {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 80px;
    background-color: #7800E8;
    color: white;
    padding: 0 20px;
    margin-bottom: 0;
    font-size: 16px;
}

/* Logo or brand name */
.top-bar__logo {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

/* Navigation links */
.top-bar__links {
    list-style: none;
    display: flex;
    gap: 20px;
}

.top-bar__links li {
    padding: 10px;
}

.top-bar__links a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

/* Active link */
.top-bar__links a.active {
    color: #ff0000;
}

.nav-button-container {
    display:flex;
    justify-content: space-between;
    margin-left: 20px;
}

.nav-button {
    color: white;
    background: transparent;
    border: none;
    border-radius: 3px;
    font-size: 20px;
}
/* Nav button on hover */
.nav-button:hover {
    background-color: #5b00b1;
    color: white;
}

/* Nav button on click */
.nav-button:active {
    /* Add your styles here */
}