:root {
  --barrelfire-primary-color: #e87800;
  --barrelfire-secondary-color: #7800E8;
  --barrelfire-ugly-color: #00E878;
}
.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MainContent {
  background-color: #282c34;
  color: white;
  height: 100%;
  width: 100%;
}