.Home {
    display: flex;
    min-height: 100%;
    min-width: 100%;
    align-items: center;
    width: 100%;
    flex-grow: 1; /* Change this line */
    background-color: #282c34;
    color: white;
    font-size:xx-large;
}

.ComingSoon {
    margin: 0;
    min-width: 100%;
    height: 100%;
}

